import React from 'react';

function MeditationPage() {
    return (
        <div>
            <header>
                <h1>Guided Meditation</h1>
            </header>
            <main>
                <p>Explore various meditation techniques to relax your mind and body.</p>
            </main>
        </div>
    );
}

export default MeditationPage;
