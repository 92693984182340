import React from 'react';

function ResourcesPage() {
    return (
        <div>
            <header>
                <h1>Mental Health Resources</h1>
            </header>
            <main>
                <p>Here you'll find articles, videos, podcasts, and more on mental health.</p>
            </main>
        </div>
    );
}

export default ResourcesPage;
