import React from 'react';

function JournalingPage() {
    return (
        <div>
            <header>
                <h1>Journaling</h1>
            </header>
            <main>
                <p>Start journaling to track your thoughts and emotions daily.</p>
            </main>
        </div>
    );
}

export default JournalingPage;
