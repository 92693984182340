import React from 'react';

function EmergencyHelpPage() {
    return (
        <div>
            <header>
                <h1>Emergency Help</h1>
            </header>
            <main>
                <p>If you are in a crisis, here are emergency contact details and resources.</p>
            </main>
        </div>
    );
}

export default EmergencyHelpPage;
