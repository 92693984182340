// /src/pages/GamePage.js

import React, { useState } from 'react';

const scenarios = [
    {
        question: "You have a big presentation tomorrow, and you feel overwhelmed with anxiety. What do you do?",
        options: [
            { text: "doingchanges", points: 0, feedback: "Pushing through can increase anxiety. Take care of yourself!" },
            { text: "Take a break and breathe", points: 10, feedback: "Great choice! Relaxation can reduce anxiety." },
            { text: "Procrastinate by watching TV", points: -5, feedback: "Procrastination increases stress. Break your task into small steps!" },
            { text: "Talk to a friend", points: 5, feedback: "Sharing emotions helps! Keep reaching out for support." }
        ]
    },
    // Add more scenarios if needed
];

const GamePage = () => {
    const [currentScenario, setCurrentScenario] = useState(0);
    const [score, setScore] = useState(0);
    const [feedback, setFeedback] = useState("");

    const handleOptionClick = (points, feedback) => {
        setScore(score + points);
        setFeedback(feedback);
        setTimeout(() => {
            setFeedback("");
            setCurrentScenario((prev) => (prev + 1) % scenarios.length);
        }, 3000);
    };

    const { question, options } = scenarios[currentScenario];

    return (
        <div className="game-container">
            <h2>Emotional Adventure</h2>
            <p>Score: {score} Emotion Gems</p>

            <div className="scenario">
                <h3>{question}</h3>
                {options.map((option, index) => (
                    <button
                        key={index}
                        onClick={() => handleOptionClick(option.points, option.feedback)}
                    >
                        {option.text}
                    </button>
                ))}
            </div>

            {feedback && <p className="feedback">{feedback}</p>}
        </div>
    );
};

export default GamePage;
