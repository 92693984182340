import React from 'react';

function BookingPage() {
    return (
        <div>
            <header>
                <h1>Book a Therapy Appointment</h1>
            </header>
            <main>
                <p>Schedule an appointment with a mental health professional.</p>
            </main>
        </div>
    );
}

export default BookingPage;
