// /src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage'; // Adjust import path as needed
import AssessmentPage from './pages/AssessmentPage';
import ChatPage from './pages/ChatPage';
import ResourcesPage from './pages/ResourcesPage';
import MeditationPage from './pages/MeditationPage';
import JournalingPage from './pages/JournalingPage';
import MoodTrackerPage from './pages/MoodTrackerPage';
import ForumPage from './pages/ForumPage';
import BookingPage from './pages/BookingPage';
import EmergencyHelpPage from './pages/EmergencyHelpPage';
import GamePage from './pages/GamePage';

function App() {
    return (
        <Router>
            <Routes>
                {/* Main routes */}
                <Route path="/" element={<HomePage />} />
                <Route path="/assessment" element={<AssessmentPage />} />
                <Route path="/chat" element={<ChatPage />} />
                <Route path="/resources" element={<ResourcesPage />} />
                <Route path="/meditation" element={<MeditationPage />} />
                <Route path="/journaling" element={<JournalingPage />} />
                <Route path="/mood-tracker" element={<MoodTrackerPage />} />
                <Route path="/forum" element={<ForumPage />} />
                <Route path="/booking" element={<BookingPage />} />
                <Route path="/emergency-help" element={<EmergencyHelpPage />} />
                <Route path="/game" element={<GamePage />} />

                {/* Catch-all route to prevent React from intercepting backend routes */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
