import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css';

const ChatPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is authenticated and fetch user details
        fetch('/auth/check', { credentials: 'include' })
            .then(response => response.json())
            .then(data => {
                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(data.user);
                    setShowRoleSelection(true);
                } else {
                    setIsAuthenticated(false);
                }
            })
            .catch(err => {
                console.error('Error checking authentication:', err);
                setIsAuthenticated(false);
            });
    }, []);

    const handleSignIn = () => {
        window.location.href = '/auth/google';
    };

    const handleLogout = () => {
        window.location.href = '/auth/logout';
    };

    const handleUsernameCheck = () => {
        if (username.trim() === '') {
            setErrorMessage('Username cannot be empty.');
            return;
        }
        fetch(`/auth/check-username?username=${username}`)
            .then(response => response.json())
            .then(data => {
                if (data.exists) {
                    setErrorMessage('Username already exists, please choose another.');
                } else {
                    setErrorMessage('');
                }
            })
            .catch(err => console.error('Error checking username:', err));
    };

    const handleRoleSelection = (selectedRole) => {
        if (!username || !selectedRole) {
            setErrorMessage('Please enter a username and select a role.');
            return;
        }
        // Save username and role in the backend
        fetch('/auth/save-user-details', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, role: selectedRole })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Redirect based on the selected role
                    if (selectedRole === 'helpseeker') {
                        navigate('/helpseekerchat'); // Route to HelpseekerChat component
                    } else if (selectedRole === 'volunteer') {
                        navigate('/volunteerchat'); // Route to VolunteerChat component
                    }
                } else {
                    setErrorMessage('Error saving user details.');
                }
            })
            .catch(err => console.error('Error saving user details:', err));
    };

    return (
        <div className="chat-page-container">
            <header className="header">
                <h1>Live Chat with Professionals</h1>
                {isAuthenticated && user ? (
                    <div className="user-info">
                        <img src={user.image} alt="User Icon" className="user-icon" />
                        <span>{user.displayName}</span>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                ) : (
                    <div className="sign-in-container">
                        <p>You need to sign in to access the chat.</p>
                        <button onClick={handleSignIn}>Sign in with Google</button>
                    </div>
                )}
            </header>

            {isAuthenticated ? (
                showRoleSelection ? (
                    <div>
                        <label>
                            Enter Username:
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <button onClick={handleUsernameCheck}>Check Username</button>
                        </label>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <div>
                            <h3>Select Role</h3>
                            <button onClick={() => handleRoleSelection('helpseeker')}>Helpseeker</button>
                            <button onClick={() => handleRoleSelection('volunteer')}>Volunteer</button>
                        </div>
                    </div>
                ) : (
                    <p>Loading role selection...</p>
                )
            ) : (
                <div className="sign-in-container">
                    <p>You need to sign in to access the chat.</p>
                    <button onClick={handleSignIn}>Sign in with Google</button>
                </div>
            )}
        </div>
    );
};

export default ChatPage;
