// HomePage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css'; // Ensure CSS is applied

const HomePage = () => {
    const navigate = useNavigate();

    const sections = [
        { name: "Take Assessment", route: "/assessment" },
        { name: "Live Chat", route: "/chat" },
        { name: "Resources", route: "/resources" },
        { name: "Meditation", route: "/meditation" },
        { name: "Journaling", route: "/journaling" },
        { name: "Mood Tracker", route: "/mood-tracker" },
        { name: "Forum", route: "/forum" },
        { name: "Book a Therapist", route: "/booking" },
        { name: "Emergency Help", route: "/emergency-help" },
        { name: "How shall i responce ", route: "/responce-help" },
        {name: "Game", route: "/game"}
        
    ];

    return (
        <div>
            <header>Danezah Mental Health Kit</header>
            <div className="home-grid">
                {sections.map((section) => (
                    <div
                        key={section.name}
                        className="home-card"
                        onClick={() => navigate(section.route)}
                    >
                        <h2>{section.name}</h2>
                        <button>Explore</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomePage;
