import React from 'react';

function MoodTrackerPage() {
    return (
        <div>
            <header>
                <h1>Mood Tracker</h1>
            </header>
            <main>
                <p>Track your mood and identify patterns over time.</p>
            </main>
        </div>
    );
}

export default MoodTrackerPage;
