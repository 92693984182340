import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import '../styles/main.css';

const questions = [
    { id: 1, text: "Have you felt little interest or pleasure in doing things?", category: "Depression" },
    { id: 2, text: "Have you felt hopeless about the future?", category: "Depression" },
    { id: 3, text: "Have you been feeling nervous, anxious, or on edge?", category: "Anxiety" },
    { id: 4, text: "Do you worry excessively about different things?", category: "Anxiety" },
    { id: 5, text: "Do you feel overwhelmed by responsibilities?", category: "Stress" },
    { id: 6, text: "Do you often feel physically tired or fatigued?", category: "Fatigue" },
    { id: 7, text: "Have you experienced sudden mood changes?", category: "Mood Stability" },
    { id: 8, text: "Do you avoid social situations?", category: "Social Connection" },
    { id: 9, text: "Do you feel supported by friends or family?", category: "Social Connection" },
    { id: 10, text: "Do you experience difficulty falling or staying asleep?", category: "Sleep" },
    { id: 11, text: "Do you feel easily irritated or frustrated?", category: "Mood Stability" },
    { id: 12, text: "Have you had panic attacks recently?", category: "Anxiety" },
    { id: 13, text: "Do you feel that you are managing your daily responsibilities well?", category: "Stress" },
    { id: 14, text: "Have you lost your appetite or had excessive cravings?", category: "Mood Stability" },
    { id: 15, text: "Do you feel emotionally connected with others?", category: "Social Connection" },
];

const options = ["Never", "Rarely", "Sometimes", "Often", "Always"];

const AssessmentPage = () => {
    const [name, setName] = useState('');
    const [answers, setAnswers] = useState({});
    const [completed, setCompleted] = useState(false);
    const [nameSubmitted, setNameSubmitted] = useState(false);
    const navigate = useNavigate();
    const resultRef = useRef(null); // Reference to the results section

    const handleAnswerChange = (questionId, value) => {
        setAnswers({ ...answers, [questionId]: value });
    };

    const calculateResult = () => {
        const categoryScores = {};
        const categoryCounts = {};

        questions.forEach((question) => {
            const answer = parseInt(answers[question.id] || 0);
            categoryScores[question.category] = (categoryScores[question.category] || 0) + answer;
            categoryCounts[question.category] = (categoryCounts[question.category] || 0) + 1;
        });

        const normalizedScores = {};
        Object.keys(categoryScores).forEach((category) => {
            const maxScore = categoryCounts[category] * 4;
            normalizedScores[category] = (categoryScores[category] / maxScore) * 100;
        });

        return normalizedScores;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setCompleted(true);
    };

    const renderBar = (percentage) => {
        let color = "green";
        if (percentage > 70) color = "red";
        else if (percentage > 40) color = "yellow";

        return (
            <div className="result-bar">
                <div
                    className="bar-fill"
                    style={{ width: `${percentage}%`, backgroundColor: color }}
                ></div>
            </div>
        );
    };

    const renderResults = () => {
        const results = calculateResult();

        return (
            <div className="result" ref={resultRef}>
                <h2>Assessment Results for {name}</h2>
                {Object.keys(results).map((category) => (
                    <div key={category} className="result-item">
                        <p>{category}</p>
                        {renderBar(results[category])}
                    </div>
                ))}
                <button className="back-button" onClick={() => navigate('/')}>Back to Home</button>
                <button className="back-button" onClick={captureScreenshot}>Share Results</button>
            </div>
        );
    };

    const captureScreenshot = async () => {
        if (resultRef.current) {
            try {
                const canvas = await html2canvas(resultRef.current);
                const imageDataUrl = canvas.toDataURL('image/png');

                const shareMessage = `Check out ${name}'s mental health assessment results on Danezah!`;
                const assessmentLink = "https://danezah.com/assessment";

                if (navigator.share && /Mobi|Android/i.test(navigator.userAgent)) {
                    // Mobile device with Web Share API support
                    const blob = await fetch(imageDataUrl).then(res => res.blob());
                    const filesArray = [new File([blob], 'assessment-results.png', { type: 'image/png' })];

                    try {
                        await navigator.share({
                            title: `Assessment Results for ${name}`,
                            text: shareMessage,
                            url: assessmentLink,
                            files: filesArray,
                        });
                    } catch (error) {
                        console.error("Error sharing on mobile:", error);
                    }
                } else {
                    // Desktop: Download image directly
                    const link = document.createElement('a');
                    link.href = imageDataUrl;
                    link.download = `assessment-results-${name}.png`;
                    link.click();
                }
            } catch (error) {
                console.error("Error capturing screenshot:", error);
            }
        }
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        setNameSubmitted(true);
    };

    return (
        <div className="assessment-container">
            <header>
                <h1>Free Mental Health Assessment - Danezah</h1>
            </header>

            {!nameSubmitted ? (
                <form className="name-form" onSubmit={handleNameSubmit}>
                    <h2 className="form-title">What Shall we call you ? 😊</h2>
                    <label htmlFor="name" className="name-label">Enter your name to get started:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="name-input"
                    />
                    <button type="submit" className="submit-button">Start Assessment</button>
                </form>
            ) : !completed ? (
                <form className="assessment-form" onSubmit={handleSubmit}>
                    {questions.map((question) => (
                        <div key={question.id} className="question">
                            <p>{question.text}</p>
                            <div className="options">
                                {options.map((option, index) => (
                                    <label key={index} className="option-label">
                                        <input
                                            type="radio"
                                            name={`question-${question.id}`}
                                            value={index}
                                            onChange={() => handleAnswerChange(question.id, index)}
                                            required
                                        />
                                        {option}
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                    <button type="submit" className="submit-button">Submit Assessment</button>
                </form>
            ) : (
                renderResults()
            )}
        </div>
    );


};

export default AssessmentPage;
