import React from 'react';

function ForumPage() {
    return (
        <div>
            <header>
                <h1>Community Forum</h1>
            </header>
            <main>
                <p>Join discussions with others who are facing similar mental health challenges.</p>
            </main>
        </div>
    );
}

export default ForumPage;
